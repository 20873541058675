import React from 'react';
import ResultSelectPageTemplate from "../../../../libs/templates/pageTemplates/ResultSelectPageTemplate";

const ResultSelection = () => <ResultSelectPageTemplate
  voucherFormIsSubmitOutside={false}
  backBtnCustomClass='back-to-main-btn__secondary'
  isResultsCountryBtnOutside={true}
/>;

export default ResultSelection;
