import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Header from '../../../../libs/components/Header';
import TextSection from '../../../../libs/components/Sections/TextSection';
import Footer from '../../../../libs/components/Footer';
import LotterySection from '../../../../libs/components/Sections/LotterySection';
import Lottery from '../../../../libs/components/Lottery/Variant2';
import FormPopup from '../../../../libs/components/ContactForm/FormPopup';
import FormPopupText from '../../../../libs/components/ContactForm/FormPopupText';
import LuckyNumberSection from '../../../../libs/components/Sections/ViewResultsButtonSection';
import LuckyNumbersInfo from '../../../../libs/components/LuckyNumbersForm/LuckyNumbersInfo/Variant2';
import YourLuckyNumbersPage from '../../../../libs/components/YourLuckyNumbersPage/Variant2';
import ViewResultsButtonSection from '../../../../libs/components/Sections/ViewResultsSection';
import VoucherForm from '../../../../libs/components/VoucherForm/Variant1';
import lotteries from '../../../../libs/helpers/theme1/lotteries';
import generateLotteriesBalls from '../../../../libs/helpers/lotteryBallsGenerator';
import { formatBalance } from '../../../../libs/helpers/voucher';
import useVoucherHandler from '../../../../libs/hooks/useVoucherHandler';
import lotterySrc1 from '../../../../libs/images/lottery-1.png';
import lotterySrc2 from '../../../../libs/images/lottery-2.png';
import lotterySrc3 from '../../../../libs/images/lottery-3.png';
import lotterySrc4 from '../../../../libs/images/lottery-4.png';
import lotterySrc5 from '../../../../libs/images/lottery-5.png';
import lotterySrc6 from '../../../../libs/images/lottery-6.png';
const LUCKY_NUMBER_PRICE = Number.parseInt(process.env.REACT_LUCKY_NUMBER_PRICE);

const Homepage = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [lotteriesBalls, setLotteriesBalls] = useState(null);
  const [
    handleSubmitVoucher,
    isVoucherPopupOpen,
    voucherPopupOpenHandler,
    voucherPopupCloseHandler,
    error,
    setError,
    info,
    loading
  ] = useVoucherHandler(() => {
    setLotteriesBalls(generateLotteriesBalls(lotteries));
  }, LUCKY_NUMBER_PRICE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [lotteriesBalls]);

  const contactFormOpen = e => {
    e.preventDefault();
    setIsContactFormOpen(true);
  };

  const contactFormClose = () => {
    setIsContactFormOpen(false);
  };

  const backToMainHandler = () => {
    setLotteriesBalls(null);
  };

  return (
    <div className="app">
      <div className="app__top">
        <Header />
        {
          lotteriesBalls ?
            <main className="app__generated-numbers">
              <YourLuckyNumbersPage lotteriesNumbers={lotteriesBalls} buttonHandler={backToMainHandler}/>
            </main>
            :
            <main>
              <TextSection/>
              <LotterySection>
                <Lottery title='Lotto' src={lotterySrc1}/>
                <Lottery title='Lotto plus 1' src={lotterySrc2} customClass='big-image'/>
                <Lottery title='Lotto plus 2' src={lotterySrc3} customClass='big-image'/>
                <Lottery title='Daily lotto' src={lotterySrc4} customClass='big-image'/>
                <Lottery title='Powerball' src={lotterySrc5}/>
                <Lottery title='Powerball plus' src={lotterySrc6} customClass='lottery__last'/>
                <ViewResultsButtonSection>
                  <Link to='/result-select/' className='button'>View results</Link>
                </ViewResultsButtonSection>
              </LotterySection>
              <LuckyNumberSection>
                <LuckyNumbersInfo
                  voucherPopupOpenHandler={voucherPopupOpenHandler}
                  datePlaceholder='Date of Birth'
                  genderLabelEnable={false}
                />
              </LuckyNumberSection>
              <FormPopupText clickHandler={contactFormOpen}/>
            </main>
        }
      </div>
      <Footer />
      {
        isVoucherPopupOpen &&
        <VoucherForm
          onClose={voucherPopupCloseHandler}
          onSubmitVoucher={handleSubmitVoucher}
          error={error}
          setError={setError}
          info={info}
          loading={loading}
          isSubmitOutside={false}
          title={<h5>Your lucky numbers are generated.<br/> Enter { formatBalance(LUCKY_NUMBER_PRICE) } voucher to reveal</h5>}
        />
      }
      {
        isContactFormOpen &&
        <FormPopup
          title={<h4>Contact us for advertising <br /> and backlinks</h4>}
          closeHandler={contactFormClose}
          namePlaceholder='Enter your name:'
          emailPlaceholder='Enter your Email:'
          messagePlaceholder='Enter your message:'
        />
      }
    </div>
  );
};

export default Homepage;
