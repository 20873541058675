import React from 'react';

const Lottery = ({ title, src, customClass = '' }) => (
  <div className={"lottery " + customClass}>
    <div className="lottery__inner">
      <div className="lottery__inner-content">
        <img src={src} className='lottery__image' alt={title}/>
        <h6 className="lottery__title">{title}</h6>
      </div>
    </div>
  </div>
);

export default Lottery;
